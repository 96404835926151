
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ClientCommandsService } from '@services/ClientCommands.service';
import { ContextService } from './@core/services/Context.service';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  options = {
    className: 'p-confirm-popup-info'
  };
  title = 'Garage';

  constructor(
    protected readonly client: ClientCommandsService,
    protected readonly router: Router,
    protected readonly translateService: TranslateService,
    protected readonly contextService: ContextService,
    protected readonly titleService: Title,
    private config: PrimeNGConfig,
  ) {
    this.titleService.setTitle(`Application loading... | ${this.title}`);
  }
  
  ngOnInit() {
    this.client.onConfirmOptions()
      .subscribe((options => {
        this.options = options;
      }));

    this.contextService.context$.subscribe((context) => {
        const lang = context.currentLanguage.languageIetTag;
        this.translateService.use(lang);
        this.translate(lang);
    });

  }

  translate(lang: string) {
    this.translateService.get('primeng').subscribe(res => {
      this.config.setTranslation(res);
    });
  }

}